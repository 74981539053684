import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import * as styles from "./Banner.module.scss"

const Banner = () => (
  <section className={styles.section}>
    <Container className="text-center">
      <h1 className="main-banner-heading-55">Dedicated Development Team</h1>
      <div>
        Hire dedicated developers team to enhance your business operations.
      </div>
      <div className="mt-5">
        <Link to="/contact-us/">
          <Button className="btn_white_border" id="ddt_hero_section_gt">
            Build Your Team
          </Button>
        </Link>
      </div>
      <div className="mt-5">
        <StaticImage
          src="../../images/dd-team-banner-illust.png"
          alt="dedicated development team"
          placeholder="blurred"
          decoding="async"
          loading="lazy"
          imgStyle={{
            width: "80%",
            height: "80%",
            margin: "auto",
            marginTop: "0px",
          }}
        />
      </div>
    </Container>
  </section>
)

export default Banner
