import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import modelIcon1 from "../../images/icons/model-icon1.png"
import modelIcon2 from "../../images/icons/model-icon2.png"
import modelIcon3 from "../../images/icons/model-icon3.png"
import modelIcon4 from "../../images/icons/model-icon4.png"
import * as styles from "./IdealTeam.module.scss"

const IdealTeam = () => {
  const items = [
    {
      icon: modelIcon1,
      title: "You are a company that needs to extend expertise",
      description:
        "Get development specialists with the desired expertise in a quick time.",
    },
    {
      icon: modelIcon2,
      title: "You are a business with tight deadlines",
      description:
        "Acquire a team of offshore software development experts to fit in a tight schedule and swiftly boost your capacity.",
    },
    {
      icon: modelIcon3,
      title: "You are a startup that needs to scale faster",
      description:
        "Leverage dedicated development team model and spend no time on local hiring.",
    },
    {
      icon: modelIcon4,
      title: "You are a business looking to manage offshore IT specialists",
      description:
        "Be in charge of the software development process, while avoiding administration.",
    },
  ]
  return (
    <section className={styles.section}>
      <Container>
        <Row className="align-items-center">
          <Col md={12} lg={5}>
            <h2 className="text-center">
              <span className="text-primary">
                Dedicated Development <br /> Team Model
              </span>{" "}
              is Ideal If
            </h2>
          </Col>
          <Col md={12} lg={7} className="mt-5 mt-lg-0 ml-4 ml-lg-0">
            <Row>
              {items.map(({ icon, title, description }, i) => (
                <Col md={6} key={i} className={styles.card}>
                  <img decoding="async" loading="lazy" src={icon} alt={title} />
                  <div className="h5">{title}</div>
                  <div>{description}</div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default IdealTeam
