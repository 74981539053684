import React from "react"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import adv1 from "../../images/icons/adv-1.svg"
import adv2 from "../../images/icons/adv-2.svg"
import adv3 from "../../images/icons/adv-3.svg"
import adv4 from "../../images/icons/adv-4.svg"
import adv5 from "../../images/icons/adv-5.svg"
import adv6 from "../../images/icons/adv-6.svg"
import * as styles from "./Advantages.module.scss"

const Advantages = () => {
  const items = [
    {
      icon: adv1,
      title: "Versatile Team of Professionals",
      details:
        "Bring together people with various qualifications and specializations.",
    },
    {
      icon: adv2,
      title: "Efficient Services",
      details:
        "As the team grows, it achieves maximum productivity with exact skill set and knowledge.",
    },
    {
      icon: adv3,
      title: "Continuity",
      details: "Swiftly integrates with your internal team and infrastructure.",
    },
    {
      icon: adv4,
      title: "Transparent Pricing",
      details: "Transparent pricing at every stage of your project.",
    },
    {
      icon: adv5,
      title: "Rapid Ramp-Up Times",
      details:
        "Ready to go dedicated development team of software development experts.",
    },
    {
      icon: adv6,
      title: "Fluid Work Flow",
      details:
        "Fast turnarounds and uninterrupted development with suitable tools & methodologies.",
    },
  ]
  return (
    <section className={styles.section}>
      <Container>
        <div>
          <h2>
            Advantages of a <br />
            <span className="text-primary">Dedicated Team Model</span>
          </h2>
        </div>
        <Row>
          {items.map(({ icon, title, details }, i) => (
            <Col sm={12} md={6} lg={4} key={i} style={{ padding: 15 }}>
              <Card>
                <Card.Body>
                  <div className="mb-3">
                    <img
                      decoding="async"
                      loading="lazy"
                      src={icon}
                      alt={title}
                      height={38}
                    />
                  </div>
                  <Card.Title>
                    <h3 className="h5">{title}</h3>
                  </Card.Title>
                  <Card.Text>{details}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Advantages
