import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./MidHero.module.scss"

const MidHero = () => (
  <section className={styles.section}>
    <Container>
      <Row className="align-items-center">
        <Col className="text-center" xs={12} md={6}>
          <StaticImage
            placeholder="blurred"
            decoding="async"
            loading="lazy"
            src="../../images/deticated-remote-team.png"
            alt="Hire a Dedicated Team of Software Experts"
          />
        </Col>
        <Col xs={12} md={6}>
          <h2>Hire a Dedicated Team of Software Experts</h2>
          <div className="pt-1">
            InvoZone has 500+ business analysts, software developers, project
            managers, and Quality Assurance experts. You can hire a dedicated
            team of developers including React JS and Elixir developers, mobile
            app developers, JAVA developers, and software architects for your
            software development projects.
          </div>
          <div className="mt-5">
            <Link to="/contact-us/">
              <Button className="btn_white_border" id="ddt_body_section_gt">
                Build Your Team
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
)

export default MidHero
