import React from "react"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./WhyDDevs.module.scss"

const WhyDDevs = () => {
  const items = [
    {
      title: "Immediate Access To Software Developer",
      description:
        "Acquire expert software developers with extensive experience in software solutions development.",
    },
    {
      title: "Streamline Budget And Save Money",
      description:
        "Hire a dedicated software development team, to get control over team size, engagement timeline, and overall budget.",
    },
    {
      title: "Access To All Major Technologies",
      description:
        "Gain access to experienced programmers with expertise in multiple technologies.",
    },
  ]
  return (
    <section className={styles.section}>
      <Container>
        <div className="text-center mb-4">
          <h2>
            Why Hire A
            <span className="text-primary"> Dedicated Development Team?</span>
          </h2>
        </div>
        <Row>
          {items.map(({ title, description }, i) => (
            <Col lg={4} className={styles.marginBottom} key={i}>
              <Card className={styles.whyDDevsCard}>
                <Card.Body>
                  <Card.Title>
                    <h3 className="h5">{title}</h3>
                  </Card.Title>
                  <Card.Text>{description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default WhyDDevs
